import { Box, Button, HStack, Stack, Text } from 'native-base'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { useOrderPageContext } from '../../OrderPageContext'
import { DeliveryStatusType, SafePlace as SafePlaceType } from '../../types'
import { useIsTiktokSafePlace } from '../shared/useIsTiktokSafePlace'
import usePrevious from '../shared/usePrevious'
import {
  getFallbackSafePlaceList,
  NEIGHBOUR_ID,
  neighbourMaxCharacterLimit,
  safePlaceIcons,
} from './safePlaceList'
import SafePlaceListRadioGroup from './SafePlaceListRadioGroup'
import SafePlaceMultiCheckbox from './SafePlaceMultiCheckbox'
import useSafePlace, { getSelectedSafePlace } from './useSafePlace'

const safePlceIconSize = 50
const SafePlaceIcon = styled.img`
  width: ${safePlceIconSize}px;
  height: ${safePlceIconSize}px;
`

const checkboxText =
  'By selecting this, you take responsibility for the loss or damage of any item delivered here'

const SafePlace: React.FC = () => {
  const {
    orderData: {
      safePlacesList: safePlacesListFromBE,
      safePlacePreference,
      deliveryStatus,
    },
  } = useOrderPageContext()
  const isTiktok = useIsTiktokSafePlace()
  const isDelivered = deliveryStatus === DeliveryStatusType.Delivered

  const prevSafePlacePreference =
    usePrevious<typeof safePlacePreference>(safePlacePreference)

  const {
    loading,
    error,
    onSaveSafePlace,
    selectedPlace,
    setSelectedPlace,
    neighbourDoorNumber,
    setNeighbourDoorNumber,
    clearSafePlace,
  } = useSafePlace()

  const [isEditMode, setIsEditMode] = useState<boolean>(
    selectedPlace.length === 0
  )

  const isNeighbourSelected = selectedPlace.some((place) =>
    Boolean(place?.id === NEIGHBOUR_ID)
  )

  // if BE sends empty array, we assume it's intentional and will use it still
  const safePlacesList =
    safePlacesListFromBE ?? getFallbackSafePlaceList(isTiktok)
  const showUpdateButton = !isEditMode && selectedPlace.length > 0

  const handleChangeSavePlace = (safePlaceId: string) => {
    const safePlace = safePlacesList.find(
      (place: SafePlaceType) => place.id === safePlaceId
    )
    setSelectedPlace(safePlace ? [safePlace] : [])
    if (safePlace?.id !== NEIGHBOUR_ID) {
      setNeighbourDoorNumber('')
    }
  }

  const handleSaveSafePlace = async () => {
    gtag?.('event', 'save_safe_place_button_click', {
      screen_name: 'deliveryInstructionsMenuOptions',
      button_name: 'save_safe_place',
    })

    if (selectedPlace) {
      await onSaveSafePlace()
      setIsEditMode(false)
    }
  }

  useEffect(() => {
    const safePlaceTypeChanged =
      safePlacePreference?.safePlaceType !==
      prevSafePlacePreference?.safePlaceType
    const safePlacesListChanged =
      JSON.stringify(safePlacePreference?.safePlaces) !==
      JSON.stringify(prevSafePlacePreference?.safePlaces)

    if (safePlaceTypeChanged || safePlacesListChanged) {
      // safe place could be updated somewhere else. In this case we want to
      // honer that first
      setSelectedPlace(
        safePlacePreference
          ? getSelectedSafePlace(safePlacePreference, isTiktok)
          : []
      )
      setIsEditMode(false)
    }
  }, [prevSafePlacePreference, safePlacePreference, setSelectedPlace, isTiktok])

  return (
    <Stack mb={6}>
      <HStack justifyContent={'space-between'} alignItems={'center'}>
        <Text fontWeight={'bold'} py={2}>
          Your preferred safe place
        </Text>
        {isEditMode && !!selectedPlace && (
          <Button
            isLoading={loading}
            onPress={() => {
              gtag?.('event', 'clear_safe_place_button_click', {
                screen_name: 'deliveryInstructionsMenuOptions',
                button_name: 'clear_safe_place',
              })
              setSelectedPlace([])
              clearSafePlace()
            }}
            py={2}
            px={4}
            bgColor={'relay.gray'}
            borderRadius={'full'}
            _text={{
              color: 'relay.volt',
            }}
            isDisabled={isDelivered}
          >
            Clear
          </Button>
        )}
        {showUpdateButton && (
          <Button
            onPress={() => {
              setIsEditMode(true)
            }}
            py={2}
            px={4}
            bgColor={'relay.gray'}
            borderRadius={'full'}
            _text={{
              color: 'relay.volt',
            }}
            isDisabled={isDelivered}
          >
            Update
          </Button>
        )}
      </HStack>
      {isTiktok && isEditMode && (
        <Box mb={2}>
          <Text>
            Let your courier know all the places you’re happy for them to
            deliver the parcel
          </Text>
        </Box>
      )}
      <Stack mt={4}>
        {!isEditMode && selectedPlace.length > 0 ? (
          <>
            {selectedPlace.map((place) => {
              if (!place?.id) {
                return null
              }

              return (
                <HStack key={place.id} alignItems={'center'} space={2}>
                  {!isTiktok && safePlaceIcons[place.id] && (
                    <SafePlaceIcon
                      src={safePlaceIcons[place.id]}
                      alt={place.label}
                    />
                  )}
                  <Text fontSize={'lg'}>{place?.label}</Text>
                  {isNeighbourSelected && place.id === NEIGHBOUR_ID && (
                    <Text>{neighbourDoorNumber}</Text>
                  )}
                </HStack>
              )
            })}
          </>
        ) : (
          <>
            {isTiktok ? (
              <SafePlaceMultiCheckbox
                selectedPlace={selectedPlace}
                safePlacesList={safePlacesList}
                loading={loading}
                isDisabled={isDelivered}
                neighbourDoorNumber={neighbourDoorNumber}
                onChangeSelectedSafePlace={(ids: string[]) => {
                  setSelectedPlace(
                    ids
                      .map(
                        (id) =>
                          safePlacesList.find(
                            (place: SafePlaceType) => place.id === id
                          ) ?? null
                      )
                      .filter((place): place is SafePlaceType => !!place)
                  )
                  if (ids.includes(NEIGHBOUR_ID)) {
                    setNeighbourDoorNumber('')
                  }
                }}
                onChangeNeighbourDoorNumber={(text: string) =>
                  setNeighbourDoorNumber(
                    text.slice(0, neighbourMaxCharacterLimit)
                  )
                }
              />
            ) : (
              <SafePlaceListRadioGroup
                selectedPlace={selectedPlace}
                safePlacesList={safePlacesList}
                loading={loading}
                isDisabled={isDelivered}
                neighbourDoorNumber={neighbourDoorNumber}
                onChangeSelectedSafePlace={(id: string) =>
                  handleChangeSavePlace(id)
                }
                onChangeNeighbourDoorNumber={(text: string) =>
                  setNeighbourDoorNumber(
                    text.slice(0, neighbourMaxCharacterLimit)
                  )
                }
              />
            )}
            <Box backgroundColor={'relay.gray'} p={3} my={2}>
              <Text>
                {isTiktok
                  ? 'By setting safe places, you take responsibility for the loss or damage of any item delivered here'
                  : checkboxText}
              </Text>
            </Box>
            <Button
              bgColor={'relay.gray'}
              borderRadius={'full'}
              _text={{
                fontWeight: 'extraBold',
                color: 'relay.volt',
              }}
              alignSelf={['stretch', 'stretch', 'flex-start']}
              px={6}
              onPress={handleSaveSafePlace}
              my={4}
              isDisabled={
                !selectedPlace ||
                (isNeighbourSelected && !neighbourDoorNumber) ||
                isDelivered
              }
              isLoading={loading}
            >
              {isTiktok ? 'Set safe places' : 'Save safe place'}
            </Button>
            {!!error && <Text color={'danger.500'}>{error}</Text>}
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default SafePlace
