import { Checkbox, Input, Text, VStack } from 'native-base'
import React from 'react'

import {
  SafePlace as SafePlaceType,
  SafePlaceType as SafePlaceTypeEnum,
} from '../../types'
import { NEIGHBOUR_ID } from './safePlaceList'

type Props = {
  selectedPlace: SafePlaceType[]
  safePlacesList: SafePlaceType[]
  loading: boolean
  neighbourDoorNumber: string
  onChangeSelectedSafePlace: (ids: string[]) => void
  onChangeNeighbourDoorNumber: (text: string) => void
  isDisabled: boolean
}

function SafePlaceMultiCheckbox({
  selectedPlace,
  safePlacesList,
  loading,
  neighbourDoorNumber,
  onChangeSelectedSafePlace,
  onChangeNeighbourDoorNumber,
  isDisabled,
}: Props) {
  const isNeighbourSelected = selectedPlace.some((place) =>
    Boolean(place?.id === NEIGHBOUR_ID)
  )

  return (
    <Checkbox.Group
      defaultValue={selectedPlace.map(
        (place) => place?.id as SafePlaceTypeEnum
      )}
      onChange={(values: string[]) => {
        onChangeSelectedSafePlace(values)
      }}
    >
      {safePlacesList.map((safePlace: SafePlaceType) => (
        <div key={safePlace.id}>
          <Checkbox
            value={safePlace.id}
            isDisabled={loading || isDisabled}
            my={2}
            borderRadius={'full'}
          >
            {safePlace.label}
          </Checkbox>
          {isNeighbourSelected && safePlace.id === NEIGHBOUR_ID && (
            <VStack alignItems={'flex-start'} mt={2} mb={5} ml={8}>
              <Text fontWeight={'light'} fontSize={'sm'}>
                Door or flat number
              </Text>
              <Input
                mt={1}
                variant={'outline'}
                isFullWidth={false}
                value={neighbourDoorNumber}
                onChangeText={(text: string) =>
                  onChangeNeighbourDoorNumber(text)
                }
                fontSize={16}
                isDisabled={isDisabled}
              />
            </VStack>
          )}
        </div>
      ))}
    </Checkbox.Group>
  )
}

export default SafePlaceMultiCheckbox
