import dayjs from 'dayjs'
import React, { useEffect } from 'react'

import Timeline from '../../../../core/Timeline'
import { Event } from '../../types'

type Props = {
  events: Event[]
  isOpen?: boolean
}

function ParcelJourney({ events, isOpen }: Props) {
  useEffect(() => {
    gtag?.('event', isOpen ? 'parcel_journey_view' : 'parcel_journey_hide', {
      screen_name: 'endCustomerTrackingPage',
      button_name: 'parcel_journey_menu',
    })
  }, [isOpen])

  return (
    <Timeline
      items={events.map(
        ({
          statusDisplaySlug,
          timestamp,
          imageUrl,
          images,
          courierNote,
        }: Event) => {
          return {
            title: statusDisplaySlug,
            subtitle: dayjs(timestamp).format('MMMM DD, HH:mm'),
            imgUrl: imageUrl,
            images,
            description: courierNote,
          }
        }
      )}
    />
  )
}

export default ParcelJourney
