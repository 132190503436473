import React, { useEffect } from 'react'

import { PhoneNumberInput } from '../PhoneNumber'
import NoteToCourier from './NoteToCourier'

type Props = {
  isOpen?: boolean
  courierNote?: string | null
}

function DeliveryInstructionsContainer({ isOpen, courierNote }: Props) {
  useEffect(() => {
    gtag?.(
      'event',
      isOpen ? 'delivery_instructions_view' : 'delivery_instructions_hide',
      {
        screen_name: 'endCustomerTrackingPage',
        button_name: 'delivery_instructions_menu',
      }
    )
  }, [isOpen])

  return (
    <>
      <NoteToCourier note={courierNote} />
      <PhoneNumberInput />
    </>
  )
}

export default DeliveryInstructionsContainer
