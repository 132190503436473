import React, { ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'

import HomeIcon from '../../../../core/Icons/HomeIcon'
import NoteIcon from '../../../../core/Icons/NoteIcon'
import ParcelIcon from '../../../../core/Icons/ParcelIcon'
import PitstopIcon from '../../../../core/Icons/PitstopIcon'
import { useIsCollection } from '../../../Collection'
import { useOrderPageContext } from '../../OrderPageContext'
import {
  COLLECTION_SCHEDULED_STATUES,
  DeliveryStatusType,
  Destination,
  Event,
  OrderItem,
  SafePlacePreference,
} from '../../types'
import DeliveryDetails from '../DeliveryDetails'
import { DeliveryInstructionsContainer } from '../NoteToCourier'
import { useEnableSafePlace } from '../NoteToCourier/NoteToCourier'
import { getFallbackSafePlaceList } from '../NoteToCourier/safePlaceList'
import OrderDetails from '../OrderDetails'
import ParcelJourney from '../ParcelJourney'
import PickupInstructions from '../PickupInstructions'
import { useIsTiktokSafePlace } from '../shared/useIsTiktokSafePlace'

export enum ACCORDION_SECTION_IDS {
  deliveryDetails = 'deliveryDetails',
  parcelJourney = 'parcelJourney',
  safePlaceInstructions = 'safePlaceInstructions',
  orderDetails = 'orderDetails',
  pickupInstructions = 'pickupInstructions',
}

const OPEN_ACCORDION_PARAM = 'openSection'

const useGetOpenAccordionIdWithDefault = (): ACCORDION_SECTION_IDS => {
  const {
    orderData: { deliveryStatus },
  } = useOrderPageContext()
  const [searchParams] = useSearchParams()
  const openSectionFromParam = searchParams.get(OPEN_ACCORDION_PARAM)

  switch (openSectionFromParam) {
    case ACCORDION_SECTION_IDS.deliveryDetails:
      return ACCORDION_SECTION_IDS.deliveryDetails
    case ACCORDION_SECTION_IDS.parcelJourney:
      return ACCORDION_SECTION_IDS.parcelJourney
    case ACCORDION_SECTION_IDS.safePlaceInstructions:
      return ACCORDION_SECTION_IDS.safePlaceInstructions
    case ACCORDION_SECTION_IDS.orderDetails:
      return ACCORDION_SECTION_IDS.orderDetails
    case ACCORDION_SECTION_IDS.pickupInstructions:
      return ACCORDION_SECTION_IDS.pickupInstructions
    default:
      if (
        [
          DeliveryStatusType.CollectionExpiredReturnToSender,
          DeliveryStatusType.DeliveryFailedReturnToSender,
          DeliveryStatusType.RTSParcelDispatched,
        ].includes(deliveryStatus)
      ) {
        return ACCORDION_SECTION_IDS.parcelJourney
      }

      return deliveryStatus === DeliveryStatusType.Delivered
        ? ACCORDION_SECTION_IDS.parcelJourney
        : ACCORDION_SECTION_IDS.safePlaceInstructions
  }
}

function getDeliveryDetailsSection(destination?: Destination) {
  return {
    id: ACCORDION_SECTION_IDS.deliveryDetails,
    title: 'Delivery details',
    leadingIcon: <HomeIcon />,
    content: () =>
      destination ? <DeliveryDetails destination={destination} /> : null,
  }
}

function getParcelJourneySection(events: Event[]) {
  return {
    id: ACCORDION_SECTION_IDS.parcelJourney,
    title: 'Parcel journey',
    leadingIcon: <ParcelIcon />,
    content: (isOpen?: boolean) => (
      <ParcelJourney events={events} isOpen={isOpen} />
    ),
  }
}

function getSafePlaceSection({
  enableSafePlace,
  safePlacePreference,
  courierNote,
  isFeatured,
  isTiktok,
}: {
  enableSafePlace: boolean
  safePlacePreference?: SafePlacePreference
  courierNote?: string | null
  isFeatured?: boolean
  isTiktok?: boolean
}) {
  const getSafePlaceSubtitle = () => {
    if (!enableSafePlace) {
      return ''
    }

    const safePlaceLabel = getFallbackSafePlaceList(isTiktok).find(
      (safePlace) => safePlace.id === safePlacePreference?.safePlaceType
    )?.label

    return safePlaceLabel ?? 'Add your desired safe place'
  }

  return {
    id: ACCORDION_SECTION_IDS.safePlaceInstructions,
    title: 'Delivery instructions',
    subtitle: getSafePlaceSubtitle(),
    leadingIcon: <NoteIcon />,
    content: (isOpen?: boolean) => (
      <DeliveryInstructionsContainer
        isOpen={isOpen}
        courierNote={courierNote}
      />
    ),
    isFeatured,
  }
}

function getPickUpInstructionsSection() {
  return {
    id: ACCORDION_SECTION_IDS.pickupInstructions,
    title: 'Pickup Instructions',
    leadingIcon: <PitstopIcon />,
    content: () => <PickupInstructions />,
  }
}

export type AccordionSection = {
  /** Mainly for the `key` prop when mapping from an array */
  id: ACCORDION_SECTION_IDS
  /** The text displayed on the accordion header */
  title: string
  /** The text displayed below the title */
  subtitle?: string
  /** THe icon displayed before the title */
  leadingIcon: ReactNode
  /** The elements displayed after the accordion is opened */
  content: (isOpen?: boolean) => ReactNode
  isFeatured?: boolean
}

function useGetAccordionSections(): {
  sections: AccordionSection[]
  defaultOpenIndex: number[]
} {
  const {
    orderData: {
      destination,
      events,
      courierNote,
      orderDetails,
      safePlacePreference,
      deliveryStatus,
    },
  } = useOrderPageContext()
  const isTiktok = useIsTiktokSafePlace()
  const enableSafePlace = useEnableSafePlace()
  const openAccordionId = useGetOpenAccordionIdWithDefault()

  const isWithoutSafePlace =
    !safePlacePreference && deliveryStatus !== DeliveryStatusType.Delivered

  const isCollection = useIsCollection()

  if (isCollection) {
    return getCollectionSections({
      destination,
      orderDetails,
      deliveryStatus,
      events,
      enableSafePlace,
      safePlacePreference,
      courierNote,
      openAccordionId,
      isTiktok,
    })
  }

  const sections: AccordionSection[] = [
    getDeliveryDetailsSection(destination),
    getSafePlaceSection({
      enableSafePlace,
      safePlacePreference,
      courierNote,
      isFeatured: isWithoutSafePlace,
      isTiktok,
    }),
    getParcelJourneySection(events),
  ]

  if (orderDetails) {
    const orderDetailsSection: AccordionSection = {
      id: ACCORDION_SECTION_IDS.orderDetails,
      title: 'Order details',
      leadingIcon: <ParcelIcon />,
      content: (isOpen?: boolean) => (
        <OrderDetails orderDetails={orderDetails} isOpen={isOpen} />
      ),
    }
    sections.push(orderDetailsSection)
  }

  const defaultOpenIndex = sections.findIndex(
    (section) => section.id === openAccordionId
  )
  return { sections, defaultOpenIndex: [defaultOpenIndex || 0] }
}

function getCollectionSections({
  destination,
  orderDetails,
  deliveryStatus,
  events,
  enableSafePlace,
  safePlacePreference,
  courierNote,
  openAccordionId,
  isTiktok,
}: {
  destination?: Destination
  orderDetails?: OrderItem[]
  deliveryStatus: DeliveryStatusType
  events: Event[]
  enableSafePlace: boolean
  safePlacePreference?: SafePlacePreference
  courierNote?: string | null
  openAccordionId: ACCORDION_SECTION_IDS
  isTiktok: boolean
}): {
  sections: AccordionSection[]
  defaultOpenIndex: number[]
} {
  const deliveryDetailsSection = {
    ...getDeliveryDetailsSection(destination),
    content: () => (
      <>
        {destination ? <DeliveryDetails destination={destination} /> : null}
        {orderDetails && <OrderDetails orderDetails={orderDetails} />}
      </>
    ),
  }
  const pickupInstructionsSection = getPickUpInstructionsSection()
  const parcelJourneySection: AccordionSection = getParcelJourneySection(events)

  const isWithoutSafePlace =
    !safePlacePreference && deliveryStatus !== DeliveryStatusType.Delivered
  const safePlaceInstructionsSection: AccordionSection = getSafePlaceSection({
    enableSafePlace,
    safePlacePreference,
    courierNote,
    isFeatured: isWithoutSafePlace,
    isTiktok,
  })

  let sections: AccordionSection[] = []
  if (COLLECTION_SCHEDULED_STATUES.includes(deliveryStatus)) {
    sections = [
      pickupInstructionsSection,
      parcelJourneySection,
      deliveryDetailsSection,
    ]
  } else {
    sections = [
      safePlaceInstructionsSection,
      parcelJourneySection,
      deliveryDetailsSection,
    ]
  }

  const defaultOpenIndex = sections.findIndex(
    (section) => section.id === openAccordionId
  )

  return { sections, defaultOpenIndex: [defaultOpenIndex || 0] }
}

export default useGetAccordionSections
